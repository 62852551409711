<template>
  <layout-page>
    <div class="row mt-4">
      <div class="col-12">

        <table class="table">

          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <button class="btn btn-system btn-sm" @click="deleteRow(row.id)">
                <i class="bi bi-trash2-fill"></i>
                

              </button>


            </td>

            <td>
              <div class="col-12"><strong>Usuário : </strong>{{ row.user }}</div>
            </td>

          </tr>
          <tr v-if="rows==null ">
            <td colspan="2">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="rows===false ">
            <td colspan="2" class="text-center"></td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>


  </layout-page>
</template>
<script>

import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import atributos_tarefaService from "@/services/atributos_tarefa.service";

export default {
  name: "IndexAtributosTarefas",
  components: {},
  data() {
    return {
      rows: null,
      search: null
    }
  },
  methods: {
    async list() {

      let atributos_tarefasService = new atributos_tarefaService();
      let dataRow = await atributos_tarefasService.list(this.search,this.$route.params.id);
      let helpers = new Helpers();

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async deleteRow(id) {
      let atributos_tarefasService = new atributos_tarefaService();
      let dataRow = await atributos_tarefasService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    }

  },
  created() {
    this.list();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>

<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Pagamentos</h5>
          </div>
          <div class="float-end">
            <div class="float-end">
              <div class="float-end ms-4">
                <a class="router-link-active router-link-exact-active btn btn-primary" @click="back()"> Voltar </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="row">
          <div class="col-12 text-center">
            <img v-if="!src" src="@/assets/no-image.png">
            <img v-if="src" width="300" :src="src">
            <div class="input-group">
              <input class="form-control" placeholder="loading..." type="text" id="qr_code">
              <button @click="copy()" id="btn-qr_code" class="btn btn-system">
                <i class="bi bi-clipboard-fill"></i>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </layout-page>

</template>

<script>


import LayoutPage from "@/components/page/layoutPage.vue";

import pagamentoService from "@/services/pagamento.service";
import PagamentosPixService from "@/services/pagamento_pix.service";

export default {
  name: "EditPagamentos",
  components: {LayoutPage},
  data() {
    return {
      src: null,
      qr_code: null,
    }
  },
  methods: {
    async edit(id) {

      let pagamentosService = await new pagamentoService();

      let response = await pagamentosService.view(id);

      if (response.data.status === 'novo') {
       await this.generatePix(response)
      }


      response = await pagamentosService.view(id);

      if (response.data.status === 'gerado') {

        let expirou = await this.isDateExpired();
        if (expirou) {
          await this.generatePix(response)
          response = await pagamentosService.view(id);
        }

        let payload = JSON.parse(response.data.pay_response);

        console.log(payload)
        this.src = await 'data:image/png;base64,' + payload.point_of_interaction.transaction_data.qr_code_base64;
        this.qr_code = await 'data:image/png;base64,' + payload.point_of_interaction.transaction_data.qr_code;
        document.getElementById('qr_code').value = await this.qr_code

        this.verifyPay(payload.id,response.data.id)
      }


    },
    copy() {
      let btn_qr_code = document.getElementById('btn-qr_code');
      let qr_code = document.getElementById('qr_code');
      btn_qr_code.classList.remove('btn-system');
      btn_qr_code.classList.add('btn-success');

      navigator.clipboard.writeText(qr_code.value).then(() => {
        console.log('Text copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
      setTimeout(() => {
        btn_qr_code.classList.add('btn-system');
      }, 2000)

    },
    isDateExpired(dateString) {
      // Parse the input date string to a Date object
      const inputDate = new Date(dateString);

      // Get the current date and time
      const currentDate = new Date();

      // Compare the dates
      return inputDate < currentDate;
    },
    async generatePix(response) {
      let id = await this.$route.params.id;
      let pagamentosService = await new pagamentoService();

      let payload = await new PagamentosPixService().create(response.data.valor, response.data.descritivo, response.data.email)
      response = await pagamentosService.view(id);


      let dataForm = await {
        status: 'gerado',
        pay_payload: JSON.stringify({
          "transaction_amount": response.data.valor,
          "description": response.data.descritivo,
          "installments": 1,
          "payment_method_id": "pix",
          "payer": {
            "email": response.data.emaill

          }
        }),
        pay_response: JSON.stringify(payload.data),
        codigo: payload.data.id,
        _method: "PUT"

      }


      await pagamentosService.update(dataForm, id);


    },
    async verifyPay(transaction_id,pagamento_id) {
      var interval = setInterval(async () => {
        let payload = await new PagamentosPixService().verify(transaction_id);

        if (payload.data.status === 'approved') {
          this.confirmPayment(pagamento_id)
          clearInterval(interval);
        }
      }, 3000)

    },
    confirmPayment(pagamento_id) {
     new PagamentosPixService().confirm(pagamento_id);
    },
    back() {
      try {
        this.$router.push(history.state.back)
      } catch (e) {
        this.$router.push('/pagamentos/index/'+this.$route.params.id)
      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>

<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Tarefas</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="./index" tamanho="M">
              Voltar
            </button-widget>
          </div>
          <div class="float-end">
            <button v-if="passo==='planilha'" class="btn btn-primary me-4" type="button" @click="nextForm()">
              {{ 'Próximo >>' }}
            </button>
          </div>
          <div class="float-end">
            <button v-if="passo==='formulario'" class="btn btn-primary me-4" type="button" @click="backForm()">
              {{ '<< Anterior' }}
            </button>
          </div>
          <div class="float-end">
            <button v-if="passo==='formulario'" class="btn btn-primary me-4" type="button" @click="sendForm()">Salvar
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">

      <div :class="passo==='planilha'?'':'d-none'" class="row">
        <div class="col-12 overflow-scroll">
          <div class="row">
            <div class="col-12 col-xl-4 ">

              <label for="line_separator">Separação de Linhas</label>
              <select @change="lerCSV()" class="form-control" name="line_separator" id="line_separator">
                <option value="quebra_linha">Quebra de Linha (\n)</option>
                <option value="ponto_virgula">Ponto e Vírgula (;)</option>
                <option value="virgula">Vírgula (,)</option>
                <option value="tab">Tabulação (\t)</option>
                <option value="espaco">Espaço ( )</option>
                <option value="pipe">Pipe (|)</option>

              </select>

            </div>
            <div class="col-12 col-xl-4 ">
              <label for="column_separator">Separador de Colunas</label>
              <select @change="lerCSV()" class="form-control" name="column_separator" id="column_separator">
                <option value="virgula">Vírgula (,)</option>
                <option value="ponto_virgula">Ponto e Vírgula (;)</option>
                <option value="tab">Tabulação (\t)</option>
                <option value="espaco">Espaço ( )</option>
                <option value="pipe">Pipe (|)</option>
                <option value="dois_pontos">Dois Pontos (:)</option>
              </select>

            </div>
            <div class="col-12 col-xl-4 ">
              <label for="csv_file">Arquivo CSV para Importação</label>
              <input @change="lerCSV()" class="form-control" type="file" id="csv_file">
            </div>

          </div>
          <table class="table table-responsive ">
            <thead>
            <tr>
              <td v-for="(col, colIndex) in columns" v-bind:key="colIndex">

                <input v-if="colIndex>0" class="form-control" type="text" :id="'column_'+colIndex" :value="colIndex">
              </td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row,index) in csv" :key="index">
              <td>
                <button @click="deleteRow(index)" class="btn btn-danger">
                  <i class="bi bi-trash2-fill"></i>
                </button>
              </td>
              <td style="width: 50px" v-for="(col, colIndex) in row" :key="colIndex">
                {{ new StrUtil().truncateString(col) }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div :class="passo==='formulario'?'':'d-none'" class="row">
        <div class="col-12">


        </div>
        <div class="col-12">
          <div class="row">
            <input-form placeholder="Selecione Prioridade  " class-list="col-12 col-xl-4 " type="select"
                        :items="prioridades"
                        label="Prioridade  " value="" name="prioridade_id"/>
            <input-form placeholder="Selecione Categoria " class-list="col-12 col-xl-4 " type="select"
                        :items="categorias"
                        label="Categoria " value="" name="categoria_id"/>
            <input-form placeholder="Selecione Estado " class-list="col-12 col-xl-4 " type="select" :items="estados"
                        label="Estado "
                        value="" name="estado_id"/>
            <input-form placeholder="Selecione Tipo " class-list="col-12 col-xl-4 " type="select" :items="tipos"
                        label="Tipo "
                        value="" name="tipo_id"/>
            <input-form placeholder="Selecione Sistema  " class-list="col-12 col-xl-4 " type="select" :items="sistemas"
                        label="Sistema  " value="" name="sistema_id"/>
            <input-form class-list="col-12 col-xl-4 " type="datetime" label="Data Início  " value=""
                        name="data_inicio"/>
            <input-form class-list="col-12 col-xl-4 " type="datetime" label="Data Fim  " value="" name="data_fim"/>
            <input-form class-list="col-12 col-xl-4 " type="tinyint" label="Alerta  " value="" name="alerta"/>

            <input-form placeholder="Selecione Atividade " class-list="col-12 col-xl-4" type="select2"
                        url="/api/atividades/list"
                        label="Atividade " value="" name="atividade_id"/>

          </div>
        </div>
      </div>

      <div :class="passo==='import'?'':'d-none'" class="row">
        <div class="row">
          <div class="col-12 text-center">
            Importando...
          </div>
          <div class="col-12 text-center">
            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="'width:'+loadingImport+'%'"></div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import StrUtil from "../../utils/str.util";
import InputForm from "@/components/form/inputForm.vue";

import toastr from "toastr/build/toastr.min";
import PrioridadesService from "@/services/prioridade.service";
import CategoriasService from "@/services/categoria.service";
import EstadosService from "@/services/estado.service";
import TiposService from "@/services/tipo.service";
import SistemasService from "@/services/sistema.service";
import Helpers from "@/services/Helpers";
import TarefasService from "@/services/tarefa.service";

export default {
  name: "ImportTarefas",
  computed: {
    StrUtil() {
      return StrUtil
    }
  },
  components: {InputForm, LayoutPage, ButtonWidget},
  data() {
    return {
      csv: null,
      headInput: null,
      rowsDeleted: [],
      columns: [],
      passo: 'planilha',
      prioridades: null,
      categorias: null,
      estados: null,
      tipos: null,
      produtos: null,
      atividades: null,
      sistemas: null,
      modalContato: false,
      contato: null,
      tipo_contato: null,
      categoria_contato: null,
      payload: null,
      loadingImport:0
    }
  },
  methods: {
    async sendForm() {
      try {


           let payload = {
          prioridade_id: document.getElementById('prioridade_id').value,
          categoria_id: document.getElementById('categoria_id').value,
          estado_id: document.getElementById('estado_id').value,
          tipo_id: document.getElementById('tipo_id').value,
          sistema_id: document.getElementById('sistema_id').value,
          data_inicio: document.getElementById('data_inicio').value,
          data_fim: document.getElementById('data_fim').value,
          alerta: document.getElementById('alerta').value,
          atividade_id: document.getElementById('atividade_id').value,

        };
        console.log(payload)
           if(new Helpers().empty(payload.prioridade_id)){
             toastr.warning('prioriadde não preenchida')
             return;
           }

        if(new Helpers().empty(payload.categoria_id)){
          toastr.warning('categoria não preenchida')
          return;
        }
        if(new Helpers().empty(payload.estado_id)){
          toastr.warning('estado não preenchido')
          return;
        }
        if(new Helpers().empty(payload.tipo_id)){
          toastr.warning('tipo não preenchido')
          return;
        }
        if(new Helpers().empty(payload.sistema_id)){
          toastr.warning('sistema não preenchido')
          return;
        }

        if(new Helpers().empty(payload.data_inicio)){
          toastr.warning('data de inicio não preenchida')
          return;
        }
        if(new Helpers().empty(payload.data_fim)){
          toastr.warning('data do fim não preenchida')
          return;
        }
        if(new Helpers().empty(payload.alerta)){
          toastr.warning('alerta não preenchido')
          return;
        }
        if(new Helpers().empty(payload.atividade_id)){
          toastr.warning('atividade não preenchido')
          return;
        }




        this.passo= 'import';
        this.buildDescritivo()
        let totalRows = this.payload.length;
        let rowCount = 0;
        let interval = setInterval(()=>{

         if(new Helpers().empty(this.payload[rowCount])){
           clearInterval(interval);
           toastr.success('Importado com sucesso')
           setTimeout(()=>{
             this.passo ='planilha'
           },1000)

         }else{
           new TarefasService().store(this.payload[rowCount]);
           this.loadingImport = rowCount * 100/totalRows;
           rowCount++;
         }
        },1000)
      }catch (e) {
        console.log(e)
      }

    },
    async loadData() {
      let response = await new PrioridadesService().listRow();
      this.prioridades = response?.data

      response = await new CategoriasService().listRow();
      this.categorias = response?.data

      response = await new EstadosService().listRow();
      this.estados = response?.data

      response = await new TiposService().listRow();
      this.tipos = response?.data

      response = await new SistemasService().listRow();
      this.sistemas = response?.data
    },
    buildDescritivo() {
      let descritivo = '';
      let payload = [];
      for (const row of this.csv) {
        for (let j = 0; j < row.length; j++) {
          let element = 'column_' + (Number(j) + 1)
          let head = document.getElementById(element)?.value;
          if (!new Helpers().empty(head) && !new Helpers().empty(row[j])) {
            descritivo += head;
            descritivo += ': ';
            descritivo += row[j]+ '\n';
          }

        }
        payload.push({
          prioridade_id: document.getElementById('prioridade_id').value,
          categoria_id: document.getElementById('categoria_id').value,
          estado_id: document.getElementById('estado_id').value,
          tipo_id: document.getElementById('tipo_id').value,
          sistema_id: document.getElementById('sistema_id').value,
          data_inicio: document.getElementById('data_inicio').value,
          data_fim: document.getElementById('data_fim').value,
          alerta: document.getElementById('alerta').value,
          atividade_id: document.getElementById('atividade_id').value,
          descritivo: descritivo
        });
      }
      this.payload = payload;
    },
    async lerCSV() {
      let fileInput = document.getElementById('csv_file');
      let file = fileInput?.files[0];
      if (file === undefined) {
        return
      }
      let reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        let line_separator = document.getElementById('line_separator').value;
        if (line_separator === 'ponto_virgula') {

          line_separator = ';';

        } else if (line_separator === 'virgula') {

          line_separator = '.';

        } else if (line_separator === 'tab') {

          line_separator = '  ';

        } else if (line_separator === 'espaco') {

          line_separator = ' ';

        } else if (line_separator === 'pipe') {

          line_separator = '|';

        } else if (line_separator === 'quebra_linha') {

          line_separator = '\n';


        }
        let column_separator = document.getElementById('column_separator').value;

        if (column_separator === 'ponto_virgula') {

          column_separator = ';';

        } else if (column_separator === 'virgula') {

          column_separator = ',';

        } else if (column_separator === 'tab') {

          column_separator = ' ';

        } else if (column_separator === 'espaco') {

          column_separator = ' ';

        } else if (column_separator === 'pipe') {

          column_separator = '|';

        } else if (column_separator === 'dois_pontos') {

          column_separator = ':';


        }
        const rows = this.splitCSVLine(text,line_separator)
        let i = 0;
        let csv = [];
        let countColumn = 0;

        for (const row of rows) {
          csv[i] = this.splitCSVColumn(row,column_separator)
          if (csv[i]?.length > countColumn) {
            countColumn = csv[i].length;
            if(countColumn>50){
              toastr.error('Planilha com mais de 50 colunas, por favor exclua algumas colunas')
              return ;
            }

          }

          i++;
        }
        this.columns = [];
        for (let j = 0; j < countColumn; j++) {
          this.columns.push(i);
        }


        this.csv = csv;
      }
      reader.onerror = function (event) {
        alert("Erro ao ler o arquivo: " + event.target.error);
      };

      reader.readAsText(file);
    },
    deleteRow(i) {
      if (i > -1 && i < this.csv.length) {
        this.csv = this.csv.slice(0, i).concat(this.csv.slice(i + 1));
      }
    },
    nextForm() {
      if (this.csv !== null) {

        this.passo = 'formulario';
      } else {
        toastr.warning('Importe o arquivo csv!')
      }
    },
    backForm() {
      this.passo = 'planilha';
    },
    splitCSVLine(line, separator = ',') {

   return line.split(separator);

    },
    splitCSVColumn(line, separator = ',') {

      let stringText =  line.split(separator);

      let stringTextFinal = [];

      let glue = false;

      let column = '';
      for (let i = 0; i < stringText.length; i++) {

        if(!glue && stringText[i].indexOf('"')>=0){
          column +=  stringText[i];
        }else{
          column += ' '+ stringText[i];
          glue = false;
          stringTextFinal.push(column.replaceAll('"',''))
          column = '';
        }




      }
      return stringTextFinal;
    }

  },
  mounted() {
    this.loadData();
  }
}
</script>
<style scoped>

</style>

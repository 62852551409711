<template>
      <input-form placeholder="Selecione Usuário  " class-list="col-12" type="select2" url="/api/users/list" label="Usuário" value="" name="user_id"/>


</template>

<script>
import InputForm from "@/components/form/inputForm.vue";



export default {
    name: "FormDepartamentoUsers",
    components: {InputForm},
    data() {
        return {}
    },
    methods:{},

created() {}
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>

<template>
  <nav-bar></nav-bar>

  <main class="content">

    <nav class="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0">
      <div class="container-fluid px-0">
        <menu-row></menu-row>

      </div>
    </nav>
    <div class="row mt-5 panel">
      <div class="col-12 col-sm-6 col-xl-4 mb-4">
        <div class="card border-0 shadow">
          <div class="card-body">
            <div class="row d-block d-xl-flex align-items-center">
              <div
                class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                <div class="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                  <svg class="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z">
                    </path>
                  </svg>
                </div>
                <div class="d-sm-none">
                  <h2 class="h5">Contatos</h2>
                  <h3 class="fw-extrabold mb-1">{{ contatos }}</h3>
                </div>
              </div>
              <div class="col-12 col-xl-7 px-xl-0">
                <div class="d-none d-sm-block">
                  <h2 class="h6 text-gray-400 mb-0">Contatos</h2>
                  <h3 class="fw-extrabold mb-2">{{ contatos }}</h3>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-4 mb-4">
        <div class="card border-0 shadow">
          <div class="card-body">
            <div class="row d-block d-xl-flex align-items-center">
              <div
                class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                <div class="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="icon"
                    viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                </div>
                <div class="d-sm-none">
                  <h2 class="fw-extrabold h5">Atribuidas a mim</h2>
                  <h3 class="mb-1">{{ atribuidas }}</h3>
                </div>
              </div>
              <div class="col-12 col-xl-7 px-xl-0">
                <div class="d-none d-sm-block">
                  <h2 class="h6 text-gray-400 mb-0">Atribuidas a mim</h2>
                  <h3 class="fw-extrabold mb-2">{{ atribuidas }}</h3>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-4 mb-4">
        <div class="card border-0 shadow">
          <div class="card-body">
            <div class="row d-block d-xl-flex align-items-center">
              <div
                class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                <div class="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                  <svg class="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"></path>
                  </svg>
                </div>
                <div class="d-sm-none">
                  <h2 class="fw-extrabold h5"> Tarefas </h2>
                  <h3 class="mb-1">{{ naoResolvidos }}</h3>
                </div>
              </div>
              <div class="col-12 col-xl-7 px-xl-0">
                <div class="d-none d-sm-block">
                  <h2 class="h6 text-gray-400 mb-0"> Tarefas </h2>
                  <h3 class="fw-extrabold mb-2">{{ naoResolvidos }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-container col-12 text-center" style="position: relative; height:450px; width: 100%">
        <canvas id="tarefas"></canvas>
      </div>
    </div>

  </main>
</template>
<script>


import PainelService from "@/services/painel.service";
import Chart from 'chart.js/auto';
import TarefasService from "@/services/tarefa.service";
import UserServiceice from "@/services/user.service";


export default {
  name: "DashBoardPage",

  components: {},
  data() {
    return {
      contatos: '0',
      labelContatos: '',
      atribuidas: '0',
      naoResolvidos: '0',
      labelAcessos: '',
      labelNaoResolvidos: '',
      visitas: null
    }
  },
  methods: {
    async me() {
      let userService = await new UserServiceice();
      this.user = await userService.me();
      this.userName = await this.user.data.name;
      this.role = await this.user.data.tipo;

      if (this.role === 'usuario') {
        let usuario_d_none = document.getElementsByClassName('usuario_d_none');
        for (let i = 0; i < usuario_d_none.length; i++) {
          usuario_d_none[i].classList.add('d-none');

        }
      }

    },
    async loadTotalizadores() {
      let painelService = new PainelService(process.env.VUE_APP_API_HOST_NAME);
      this.contatos = await painelService.contatos();

      this.atribuidas = await painelService.atribuidas();

      this.tarefas = await painelService.tarefas();
      this.naoResolvidos = this.tarefas

    },
    nomeEUltimoDiaDoMes() {
      // Obtém a data atual
      var dataAtual = new Date();

      // Obtém o número do mês (0 - janeiro, 1 - fevereiro, etc.)
      var numeroMes = dataAtual.getMonth();

      // Array com o nome dos meses
      var nomesMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

      // Obtém o nome do mês atual
      var nomeMesAtual = nomesMeses[numeroMes];

      // Obtém o último dia do mês atual
      var ultimoDia = new Date(dataAtual.getFullYear(), numeroMes + 1, 0).getDate();

      // Retorna um objeto com o nome do mês atual e o último dia do mês
      this.labelContatos = '1 até o dia ' + ultimoDia + ' de ' + nomeMesAtual;
      this.labelAcessos = '1 até o dia ' + ultimoDia + ' de ' + nomeMesAtual;
      this.labelNaoResolvidos = '1 até o dia ' + ultimoDia + ' de ' + nomeMesAtual;
    },
    async chartTarefas() {
      let response = await TarefasService.tarefasEstadoChart();
      const ctx = document.getElementById('tarefas');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: response.data.label,
          datasets: [{
            label: 'Tarefas por estado no mês',
            data: response.data.total,
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    }
  },
  created() {
  },
  mounted() {
    this.loadTotalizadores()
    this.chartTarefas()
    this.me()

  }

}


</script>
<style>
.panel {
  min-height: 500px;

}
</style>
<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>

<script setup>

import NavBar from "@/components/page/navBar.vue";
import MenuRow from "@/components/page/MenuRow.vue";

</script>
<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Atividades</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" :href="'/atividades/index/'+departamento_id" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormAtividades></FormAtividades>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormAtividades from "@/views/atividades/FormAtividades.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import atividadeService from "@/services/atividade.service";

export default {
  name: "EditAtividades",
  components: {LayoutPage, ButtonWidget, FormAtividades},
  data() {
    return {
      departamento_id: null
    }
  },
  methods: {
    async edit(id) {
      let atividadesService = new atividadeService();
      let response = await atividadesService.view(id);
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('descritivo').value = response.data.descritivo;
      this.departamento_id= response.data.departamento_id;

    },
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let atividadesService = new atividadeService();
      let response = await atividadesService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>

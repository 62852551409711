<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Tarefas</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="./index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormTarefas></FormTarefas>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormTarefas from "@/views/tarefas/FormTarefas.vue";
import tarefaService from "@/services/tarefa.service";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import DateUtil from "@/utils/date.util";

export default {
  name: "CreateTarefas",
  components: {FormTarefas, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        descritivo: document.getElementById('descritivo').value,
        data_inicio: document.getElementById('data_inicio').value,
        data_fim: document.getElementById('data_fim').value,
        alerta: document.getElementById('alerta').value,
        contato_id: document.getElementById('contato_id').value,
        produto_id: document.getElementById('produto_id').value,
        atividade_id: document.getElementById('atividade_id').value,
        prioridade_id: document.getElementById('prioridade_id').value,
        categoria_id: document.getElementById('categoria_id').value,
        estado_id: document.getElementById('estado_id').value,
        tipo_id: document.getElementById('tipo_id').value,
        sistema_id: document.getElementById('sistema_id').value,


      }
      let tarefasService = new tarefaService();
      let response = await tarefasService.store(dataForm);

      if (response.data?.id) {
        location.href = '/tarefas/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
      this.setInputDraft();
    },
    setInputDraft() {

      let dataForm = {

        data_inicio: document.getElementById('data_inicio').value,
        data_fim: document.getElementById('data_fim').value,

        contato_id: document.getElementById('contato_id').value,
        produto_id: document.getElementById('produto_id').value,
        atividade_id: document.getElementById('atividade_id').value,
        prioridade_id: document.getElementById('prioridade_id').value,
        categoria_id: document.getElementById('categoria_id').value,
        estado_id: document.getElementById('estado_id').value,
        tipo_id: document.getElementById('tipo_id').value,
        sistema_id: document.getElementById('sistema_id').value,


      }

      localStorage.setItem('inputDraft', JSON.stringify(dataForm))
    },
    getInputDraft() {
      let dataForm = JSON.parse(localStorage.getItem('inputDraft'))

      if (!new Helpers().empty(dataForm)) {

        document.getElementById('data_inicio').value = dataForm.data_inicio;
        document.getElementById('data_fim').value = dataForm.data_fim;
        document.getElementById('contato_id').value = dataForm.contato_id;
        document.getElementById('produto_id').value = dataForm.produto_id;
        document.getElementById('atividade_id').value = dataForm.atividade_id;
        document.getElementById('prioridade_id').value = dataForm.prioridade_id;
        document.getElementById('categoria_id').value = dataForm.categoria_id;
        document.getElementById('estado_id').value = dataForm.estado_id;
        document.getElementById('tipo_id').value = dataForm.tipo_id;
        document.getElementById('sistema_id').value = dataForm.sistema_id;
      } else {

        const now = new Date();
        const thirtyDaysFromNow = new Date();
        thirtyDaysFromNow.setDate(now.getDate() + 30);
        console.log(new DateUtil().inputFormat(now))
        document.getElementById('data_inicio').value = new DateUtil().inputFormat(now)
        document.getElementById('data_fim').value = new DateUtil().inputFormat(thirtyDaysFromNow);

        document.getElementById('prioridade_id').value = 2;
        document.getElementById('categoria_id').value = 3;
        document.getElementById('estado_id').value = 1;
        document.getElementById('tipo_id').value = 1;
        document.getElementById('sistema_id').value = 2;

      }

      document.getElementById('alerta').value = '0';


      localStorage.setItem('inputDraft', JSON.stringify(dataForm))
    }
  },
  mounted() {

    setTimeout(() => {
      this.getInputDraft();
    }, 3000)
  }
}
</script>
<style scoped>
</style>

<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Contatos</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="./index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormContatos></FormContatos>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormContatos from "@/views/contatos/FormContatos.vue";
import contatoService from "@/services/contato.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateContatos",
  components: {FormContatos, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        email: document.getElementById('email').value,
        telefone: String(document.getElementById('telefone').value).replace(/[^a-zA-Z0-9]/g, ''),
        celular: String(document.getElementById('celular').value).replace(/[^a-zA-Z0-9]/g, ''),
        cpf: String(document.getElementById('cpf').value).replace(/[^a-zA-Z0-9]/g, ''),
        cnpj: String(document.getElementById('cnpj').value).replace(/[^a-zA-Z0-9]/g, ''),
        logradouro: document.getElementById('logradouro').value,
        cidade_id: document.getElementById('cidade_id').value,
        tipo: document.getElementById('tipo').value,
        categoria: document.getElementById('categoria').value,
        empresa_id: document.getElementById('empresa_id').value,
        cep: document.getElementById('cep').value,
        numero: document.getElementById('numero').value,
        bairro: document.getElementById('bairro').value,
        complemento: document.getElementById('complemento').value,
        


      }
      let contatosService = new contatoService();
      let response = await contatosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/contatos/index';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>

<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Usuários</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" :href="'/departamento_users/index/'+departamento_id" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormDepartamentoUsers></FormDepartamentoUsers>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormDepartamentoUsers from "@/views/departamento_users/FormDepartamentoUsers.vue";
import departamento_userService from "@/services/departamento_user.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateDepartamentoUsers",
  data() {
    return {
      departamento_id: null
    }
  },
  components: {FormDepartamentoUsers, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        user_id: document.getElementById('user_id').value,
        departamento_id: this.$route.params.id,


      }
      let departamento_usersService = new departamento_userService();
      let response = await departamento_usersService.store(dataForm);

      if (response.data?.id) {
        location.href = '/departamento_users/index/' + this.$route.params.id;
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  mounted() {
    this.departamento_id = this.$route.params.id;
  }
}
</script>
<style scoped>
</style>

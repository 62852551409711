<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Pagamentos</h5>
          </div>
          <div class="float-end">
            <a class="router-link-active router-link-exact-active btn btn-primary" @click="back()"> Voltar </a>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormPagamentos></FormPagamentos>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import FormPagamentos from "@/views/pagamentos/FormPagamentos.vue";
import pagamentoService from "@/services/pagamento.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreatePagamentos",
  components: {FormPagamentos, LayoutPage},
  data() {
    return {
      tarefa_id: null
    }

  },
  methods: {
    async sendForm() {
      let dataForm = {
        descritivo: document.getElementById('descritivo').value,
        tarefa_id: this.$route.params.id,
        valor: document.getElementById('valor').value,
        email: document.getElementById('email').value,


      }
      let pagamentosService = new pagamentoService();
      let response = await pagamentosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/pagamentos/index/' + this.tarefa_id;
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    },
    back() {
      try {
        this.$router.push(history.state.back)
      } catch (e) {
        this.$router.push('/tarefas/index')
      }
    }
  },
  mounted() {
    this.tarefa_id = this.$route.params.id;
  }
}
</script>
<style scoped>
</style>

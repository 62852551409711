<template>
    <layout-page>
        <div class="card-header">
            <div class="row">
                <div class="col-12 ps-4 pt-3 ">
                    <div class="float-start">
                        <h5>Adicionar Empresas</h5>
                    </div>
                    <div class="float-end">
                        <button-widget cor="azul" href="./index" tamanho="M">
                            Voltar
                        </button-widget>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <FormEmpresas></FormEmpresas>
                    <div class="col-4">
                    <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
                    </div>
                </div>
        </div>
    </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormEmpresas from "@/views/empresas/FormEmpresas.vue";
import empresaService from "@/services/empresa.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreateEmpresas",
    components: {FormEmpresas, LayoutPage, ButtonWidget},
    methods:{
        async sendForm(){
            let dataForm = {
        nome: document.getElementById('nome').value,
descritivo: document.getElementById('descritivo').value,
cnpj: String(document.getElementById('cnpj').value).replace(/[^a-zA-Z0-9]/g, ''),


        }
            let empresasService = new empresaService();
            let response = await empresasService.store(dataForm);

            if(response.data?.id){
                location.href = '/empresas/index';
            }else{
                if (response.response.data?.message){
                    toastr.error(response.response.data?.message);
                }else{
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped>
</style>

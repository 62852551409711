<template>
  <div class="col-12 col-md-8">
    <div class="row">
      <input-form placeholder="Selecione Contato " class-list="col-10" type="select2" url="/api/contatos/list"
        label="Contato " value="" name="contato_id" />
      <div class="col-2 pt-2">
        <button class="btn btn-system mt-4" @click="openModalContato()">
          <i class="bi bi-file-person-fill"></i>
        </button>

      </div>
      <input-form placeholder="Selecione Prioridade  " class-list="col-4" type="select" :items="prioridades"
        label="Prioridade  " value="" name="prioridade_id" />
      <input-form placeholder="Selecione Categoria " class-list="col-4" type="select" :items="categorias"
        label="Categoria " value="" name="categoria_id" />
      <input-form placeholder="Selecione Estado " class-list="col-4" type="select" :items="estados" label="Estado "
        value="" name="estado_id" />
      <input-form placeholder="Selecione Tipo " class-list="col-4" type="select" :items="tipos" label="Tipo " value=""
        name="tipo_id" />
      <input-form placeholder="Selecione Sistema  " class-list="col-4" type="select" :items="sistemas" label="Sistema  "
        value="" name="sistema_id" />
      <div class="col-12">
        <label class="p-2" for="nome">
          Descritivo </label>

        <textarea style="height: 210px" name="descritivo" id="descritivo" class="descritivo form-control"></textarea>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4">
    <div class="row">
      <input-form class-list="col-12" type="datetime" label="Data Início  " value="" name="data_inicio" />
      <input-form class-list="col-12" type="datetime" label="Data Fim  " value="" name="data_fim" />
      <input-form class-list="col-12" type="tinyint" label="Alerta  " value="" name="alerta" />
      <input-form placeholder="Selecione Produto " class-list="col-12" type="select2" url="/api/produtos/list"
        label="Produto " value="" name="produto_id" />
      <input-form placeholder="Selecione Atividade " class-list="col-12" type="select2" url="/api/atividades/list"
        label="Atividade " value="" name="atividade_id" />

    </div>
  </div>

  <modal-widget-vue v-if="modalContato">
    <div class="row">
      <div class="col-12 p-2">
        <span style="font-size: 40px" @click="closeContatoModal()" class="float-end pe-4 cursor-pointer">&times;</span>
      </div>
      <div class="col-12 p-2">
        <div class="row m-2">
          <div class="col-12">
            <strong>Nome:</strong>
            <span>{{ contato.nome }}</span>
          </div>
          <div class="col-12">
            <strong>Descritivo:</strong>
            <span>{{ contato.descritivo }}</span>
          </div>
          <div class="col-12">
            <strong>Email:</strong>
            <span>{{ contato.email }}</span>
          </div>

          <div class="col-12">
            <strong>CNPJ:</strong>
            <span>{{ contato.cnpj }}</span>
          </div>

          <div class="col-12">
            <strong>Telefone:</strong>
            <span>{{ contato.telefone }}</span>
          </div>

          <div class="col-12">
            <strong>Celular:</strong>
            <span>{{ contato.celular }}</span>
          </div>

          <div class="col-12 col-xl-6 p-2">


            <select v-model="tipo_contato" @change="changeTipoContato()" name="tipo" id="tipo"
              class="tipo form-control">
              <option value="">Selecione Tipo</option>
              <option value="BAIXO">BAIXO</option>
              <option value="MEDIO">MEDIO</option>
              <option value="ALTO">ALTO</option>
            </select>

          </div>

          <div @change="changeCategoriaContato()" class="col-12 col-xl-6 p-2">

            <select v-model="categoria_contato" name="categoria" id="categoria" class="categoria form-control">
              <option value="">Selecione Categoria</option>
              <option value="LEAD">LEAD</option>
              <option value="CLIENTE">CLIENTE</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </modal-widget-vue>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";
import PrioridadesService from "@/services/prioridade.service";
import CategoriasService from "@/services/categoria.service";
import EstadosService from "@/services/estado.service";
import TiposService from "@/services/tipo.service";
import SistemasService from "@/services/sistema.service";
import contatoService from "@/services/contato.service";
import ModalWidgetVue from "@/components/widget/modalWidgetVue.vue";
import Helpers from "@/services/Helpers";
import "toastr/build/toastr.css";
import toastr from "toastr/build/toastr.min";
import ContatosService from "@/services/contato.service";


export default {
  name: "FormTarefas",
  components: { ModalWidgetVue, InputForm },
  data() {
    return {
      prioridades: null,
      categorias: null,
      estados: null,
      tipos: null,
      produtos: null,
      atividades: null,
      sistemas: null,
      modalContato: false,
      contato: null,
      tipo_contato: null,
      categoria_contato: null
    }
  },
  methods: {
    async loadData() {
      let response = await new PrioridadesService().listRow();
      this.prioridades = response?.data

      response = await new CategoriasService().listRow();
      this.categorias = response?.data

      response = await new EstadosService().listRow();
      this.estados = response?.data

      response = await new TiposService().listRow();
      this.tipos = response?.data

      response = await new SistemasService().listRow();
      this.sistemas = response?.data
    },
    async openModalContato() {
      let contato_id = document.getElementById('contato_id').value;
      let contatosService = new contatoService();
      if (new Helpers().empty(contato_id)) {
        toastr.warning('Não há contato definido.')
        return;
      }
      let response = await contatosService.view(contato_id);
      this.contato = await response.data;
      this.categoria_contato = this.contato.categoria;
      this.tipo_contato = this.contato.tipo;

      this.modalContato = true;

    },
    closeContatoModal() {
      this.modalContato = false;
    },
    changeTipoContato() {
      let tipo = document.getElementById('tipo').value;
      let contato_id = document.getElementById('contato_id').value;
      new ContatosService().changeTipo(tipo, contato_id)

    },
    changeCategoriaContato() {
      let categoria = document.getElementById('categoria').value;
      let contato_id = document.getElementById('contato_id').value;
      new ContatosService().changeCategoria(categoria, contato_id)
    }
  },

  created() {
    this.loadData();

  },
}
</script>

<style scoped></style>

<template>

    <div class="row">
        <div v-for="row in rows" :key="row.id" class="col-2 p-4 text-center"
            @click="openModalView(row.ext, row.nome, row.arquivo)">

            <div style="height: 150px;" class="card card-hover cursor-pointer">
                <div class="card-head p-2">
                    <h6>{{ this.truncateString(row.nome, 10) }}</h6>
                </div>
                <div class="card-body">
                    <i v-if="!isImageIdentify(row.ext)" class="bi bi-file-earmark-richtext-fill icon"></i>
                    <img v-if="isImageIdentify(row.ext)" style="max-height: 50px; width: 50px;" :src="row.arquivo"
                        alt="" srcset="">

                </div>

            </div>



        </div>
        <div class="col-2 p-4 text-center" @click="addAnexos()">

            <div style="height: 150px;" class="card p-2 card-hover cursor-pointer">

                <div class="card-body p-4">
                    <i class="bi bi-plus-lg icon"></i>
                </div>

            </div>



        </div>
    </div>


    <modalWidgetVue v-if="modalCreate">
        <div class="row p-4">
            <div class="col-12 pb-4">
                <i @click="modalCloseCreate()" class="bi bi-x float-end icon cursor-pointer"></i>
            </div>
            <div class="col-12">
                <create-contato-anexos></create-contato-anexos>
            </div>
        </div>
    </modalWidgetVue>
    <modalWidgetVue v-if="modalView">
        <div class="row p-4">
            <div class="col-12 pb-4">
                <i @click="modalCloseView()" class="bi bi-x float-end icon cursor-pointer"></i>
            </div>
            <div class="col-12 text-center">
                <img v-if="isImage" style="max-height: 400px;" :src="imageSRC" alt="" srcset="">
                <i v-if="!isImage" class="bi bi-file-earmark-richtext-fill text-success img-icon"></i>
            </div>

            <div class="col-12 ">
                <button class="btn btn-primary" @click="download">
                    <i class="bi bi-cloud-arrow-down-fill"></i>
                </button>
            </div>
        </div>
    </modalWidgetVue>

</template>
<script>


import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import contato_anexoService from "@/services/contato_anexo.service";
import modalWidgetVue from "@/components/widget/modalWidgetVue.vue";
import CreateContatoAnexos from "./CreateContatoAnexos.vue";
import StrUtil from "@/utils/str.util";

export default {
    name: "IndexContatoAnexos",
    components: { modalWidgetVue, CreateContatoAnexos },
    data() {
        return {
            rows: null,
            search: null,
            modalCreate: false,
            modalView: false,
            isImage: false,
            imageSRC: '',
            file: '',
            name: '',
            ext: '',
        }
    },
    methods: {
        async list() {

            let contato_anexosService = new contato_anexoService();
            let dataRow = await contato_anexosService.list(this.search);
            let helpers = new Helpers();

            if (dataRow.data.data.length > 0) {
                this.rows = dataRow.data.data;

            } else if (!helpers.empty(dataRow.response?.data)) {
                toastr.error('Houve um problema');
            } else {
                this.rows = false;
            }


        },
        async deleteRow(id) {
            let contato_anexosService = new contato_anexoService();
            let dataRow = await contato_anexosService.delete(id);
            if (dataRow.data.success) {
                this.list();
                toastr.success('Apagado com sucesso');
            } else {
                toastr.error('Houve um problema ao apagar');
            }
        },
        openModalView(ext, nome, arquivo) {
            this.imageSRC = ''

            this.isImage = this.isImageIdentify(ext);
            if (this.isImage) {
                this.imageSRC = arquivo;
            }


            this.file = arquivo;
            this.name = nome;
            this.ext = ext;

            this.modalView = true;
        },
        isImageIdentify(ext) {
            let supportedExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'svg', 'ico', 'avif'];
            return supportedExtensions.includes(ext);
        },
        download() {
            const base64 = this.file.split(',')[1];
            console.log(base64);
            const fileName = this.name + '.' + this.ext;

            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            // Criar um URL para o blob
            const blobUrl = URL.createObjectURL(blob);

            // Criar um link de download e iniciar o download
            const downloadLink = document.createElement('a');
            downloadLink.href = blobUrl;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Remover o link de download
            document.body.removeChild(downloadLink);

            // Liberar o URL do blob
            URL.revokeObjectURL(blobUrl);
        },
        addAnexos() {
            console.log('adicionar');
            this.modalCreate = true;
        },
        modalCloseCreate() {
            this.modalCreate = false;
        },
        modalCloseView() {
            this.modalView = false;
        },
        truncateString(text, length) {
            return new StrUtil().truncateString(text, length)
        }

    },
    created() {
        this.list();

    }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
<style>
.icon {
    font-size: 46px !important;
}

.img-icon {
    font-size: 300px !important;
}

.card-hover:hover {
    background-color: rgb(248, 245, 245);
}
</style>
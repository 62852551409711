import {createRouter, createWebHistory} from "vue-router";
import DashBoardPage from "@/views/pages/DashBoardPage.vue";
import LoginForm from "@/views/auth/LoginForm.vue";
import notFound from "@/views/errors/NotFound.vue";
import Middleware from "@/services/Middleware";
import IndexUsers from "@/views/users/IndexUsers.vue";
import createUsers from "@/views/users/CreateUsers.vue";
import editUsers from "@/views/users/EditUsers.vue";
import CreateCategorias from "@/views/categorias/CreateCategorias.vue";
import indexCategorias from "@/views/categorias/IndexCategorias.vue";
import editCategorias from "@/views/categorias/EditCategorias.vue";
import CreateDepartamentos from "@/views/departamentos/CreateDepartamentos.vue";
import IndexDepartamentos from "@/views/departamentos/IndexDepartamentos.vue";
import EditDepartamentos from "@/views/departamentos/EditDepartamentos.vue";
import createEstados from "@/views/estados/CreateEstados.vue";
import indexEstados from "@/views/estados/IndexEstados.vue";
import editEstados from "@/views/estados/EditEstados.vue";
import createTipos from "@/views/tipos/CreateTipos.vue";
import indexTipos from "@/views/tipos/IndexTipos.vue";
import editTipos from "@/views/tipos/EditTipos.vue";
import createContatos from "@/views/contatos/CreateContatos.vue";
import indexContatos from "@/views/contatos/IndexContatos.vue";
import editContatos from "@/views/contatos/EditContatos.vue";
import createCidades from "@/views/cidades/CreateCidades.vue";
import indexCidades from "@/views/cidades/IndexCidades.vue";
import editCidades from "@/views/cidades/EditCidades.vue";
import createAtividades from "@/views/atividades/CreateAtividades.vue";
import indexAtividades from "@/views/atividades/IndexAtividades.vue";
import editAtividades from "@/views/atividades/EditAtividades.vue";
import createSistemas from "@/views/sistemas/CreateSistemas.vue";
import indexSistemas from "@/views/sistemas/IndexSistemas.vue";
import editSistemas from "@/views/sistemas/EditSistemas.vue";
import createDepartamentoUsers from "@/views/departamento_users/CreateDepartamentoUsers.vue";
import indexDepartamentoUsers from "@/views/departamento_users/IndexDepartamentoUsers.vue";
import editDepartamentoUsers from "@/views/departamento_users/EditDepartamentoUsers.vue";
import createTarefas from "@/views/tarefas/CreateTarefas.vue";
import indexTarefas from "@/views/tarefas/IndexTarefas.vue";
import editTarefas from "@/views/tarefas/EditTarefas.vue";
import createPrioridades from "@/views/prioridades/CreatePrioridades.vue";
import indexPrioridades from "@/views/prioridades/IndexPrioridades.vue";
import editPrioridades from "@/views/prioridades/EditPrioridades.vue";
import createProdutos from "@/views/produtos/CreateProdutos.vue";
import indexProdutos from "@/views/produtos/IndexProdutos.vue";
import editProdutos from "@/views/produtos/EditProdutos.vue";
import indexArquivos from "@/views/arquivos/IndexArquivos.vue";
import createArquivos from "@/views/arquivos/CreateArquivos.vue";
import createPagamentos from "@/views/pagamentos/CreatePagamentos.vue";
import indexPagamentos from "@/views/pagamentos/IndexPagamentos.vue";
import editPagamentos from "@/views/pagamentos/EditPagamentos.vue";
import ImportTarefas from "@/views/tarefas/ImportTarefas.vue";
import createEmpresas from "@/views/empresas/CreateEmpresas.vue";
import indexEmpresas from "@/views/empresas/IndexEmpresas.vue";
import editEmpresas from "@/views/empresas/EditEmpresas.vue";




const routes = [
    {
        path: '/login',
        name: 'LoginForm',
        component: LoginForm,
        meta: {
            auth: false
        }

    },
    //user

    {
        path: '/users/create',
        name: 'usersCreate',
        component: createUsers,
        meta: {
            auth: true
        }
    },

    {
        path: '/users/index',
        name: 'users',
        component: IndexUsers,
        meta: {
            auth: true
        }
    },
    {
        path: '/users/edit/:id',
        name: 'editUsers',
        component: editUsers,
        meta: {
            auth: true
        }
    },
    //categorias
    {
        path: '/categorias/create',
        name: 'CreateCategorias',
        component: CreateCategorias,
        meta: {
            auth: true
        }
    },

    {
        path: '/categorias/index',
        name: 'indexCategorias',
        component: indexCategorias,
        meta: {
            auth: true
        }
    },
    {
        path: '/categorias/edit/:id',
        name: 'editCategorias',
        component: editCategorias,
        meta: {
            auth: true
        }
    },

    //departamentos
    {
        path: '/departamentos/create',
        name: 'CreateDepartamentos',
        component: CreateDepartamentos,
        meta: {
            auth: true
        }
    },

    {
        path: '/departamentos/index',
        name: 'IndexDepartamentos',
        component: IndexDepartamentos,
        meta: {
            auth: true
        }
    },
    {
        path: '/departamentos/edit/:id',
        name: 'EditDepartamentos',
        component: EditDepartamentos,
        meta: {
            auth: true
        }
    },

    //estados
    {
        path: '/estados/create',
        name: 'createEstados',
        component: createEstados,
        meta: {
            auth: true
        }
    },

    {
        path: '/estados/index',
        name: 'indexEstados',
        component: indexEstados,
        meta: {
            auth: true
        }
    },
    {
        path: '/estados/edit/:id',
        name: 'editEstados',
        component: editEstados,
        meta: {
            auth: true
        }
    },
    //tipos
    {
        path: '/tipos/create',
        name: 'createTipos',
        component: createTipos,
        meta: {
            auth: true
        }
    },

    {
        path: '/tipos/index',
        name: 'indexTipos',
        component: indexTipos,
        meta: {
            auth: true
        }
    },
    {
        path: '/tipos/edit/:id',
        name: 'editTipos',
        component: editTipos,
        meta: {
            auth: true
        }
    },
    //empresas
    {
        path: '/empresas/create',
        name: 'createEmpresas',
        component: createEmpresas,
        meta: {
            auth: true
        }
    },

    {
        path: '/empresas/index',
        name: 'indexEmpresas',
        component: indexEmpresas,
        meta: {
            auth: true
        }
    },
    {
        path: '/empresas/edit/:id',
        name: 'editEmpresas',
        component: editEmpresas,
        meta: {
            auth: true
        }
    },
    //contatos
    {
        path: '/contatos/create',
        name: 'createContatos',
        component: createContatos,
        meta: {
            auth: true
        }
    },

    {
        path: '/contatos/index',
        name: 'indexContatos',
        component: indexContatos,
        meta: {
            auth: true
        }
    },
    {
        path: '/contatos/edit/:id',
        name: 'editContatos',
        component: editContatos,
        meta: {
            auth: true
        }
    },

    //cidades
    {
        path: '/cidades/create',
        name: 'createCidades',
        component: createCidades,
        meta: {
            auth: true
        }
    },

    {
        path: '/cidades/index',
        name: 'indexCidades',
        component: indexCidades,
        meta: {
            auth: true
        }
    },
    {
        path: '/cidades/edit/:id',
        name: 'editCidades',
        component: editCidades,
        meta: {
            auth: true
        }
    },

    //cidades
    {
        path: '/atividades/create/:id',
        name: 'createAtividades',
        component: createAtividades,
        meta: {
            auth: true
        }
    },

    {
        path: '/atividades/index/:id',
        name: 'indexAtividades',
        component: indexAtividades,
        meta: {
            auth: true
        }
    },
    {
        path: '/atividades/edit/:id',
        name: 'editAtividades',
        component: editAtividades,
        meta: {
            auth: true
        }
    },
    //sistemas
    {
        path: '/sistemas/create',
        name: 'createSistemas',
        component: createSistemas,
        meta: {
            auth: true
        }
    },

    {
        path: '/sistemas/index',
        name: 'indexSistemas',
        component: indexSistemas,
        meta: {
            auth: true
        }
    },
    {
        path: '/sistemas/edit/:id',
        name: 'editSistemas',
        component: editSistemas,
        meta: {
            auth: true
        }
    },

    //departamento_users
    {
        path: '/departamento_users/create/:id',
        name: 'createDepartamentoUsers',
        component: createDepartamentoUsers,
        meta: {
            auth: true
        }
    },

    {
        path: '/departamento_users/index/:id',
        name: 'indexDepartamentoUsers',
        component: indexDepartamentoUsers,
        meta: {
            auth: true
        }
    },
    {
        path: '/departamento_users/edit/:id',
        name: 'editDepartamentoUsers',
        component: editDepartamentoUsers,
        meta: {
            auth: true
        }
    },
    //arquivos
    {
        path: '/arquivos/create/:id',
        name: 'createArquivos',
        component: createArquivos,
        meta: {
            auth: true
        }
    },

    {
        path: '/arquivos/index/:id',
        name: 'indexArquivos',
        component: indexArquivos,
        meta: {
            auth: true
        }
    },

    //tarefas
    {
        path: '/tarefas/import',
        name: 'ImportTarefas',
        component: ImportTarefas,
        meta: {
            auth: true
        }
    },
    {
        path: '/tarefas/create',
        name: 'createTarefas',
        component: createTarefas,
        meta: {
            auth: true
        }
    },

    {
        path: '/tarefas/:type',
        name: 'indexTarefas',
        component: indexTarefas,
        meta: {
            auth: true
        }
    },

    {
        path: '/tarefas/edit/:id',
        name: 'editTarefas',
        component: editTarefas,
        meta: {
            auth: true
        }
    },
    //prioridades
    {
        path: '/prioridades/create',
        name: 'createPrioridades',
        component: createPrioridades,
        meta: {
            auth: true
        }
    },

    {
        path: '/prioridades/index',
        name: 'indexPrioridades',
        component: indexPrioridades,
        meta: {
            auth: true
        }
    },
    {
        path: '/prioridades/edit/:id',
        name: 'editPrioridades',
        component: editPrioridades,
        meta: {
            auth: true
        }
    },

    //produtos
    {
        path: '/produtos/create',
        name: 'createProdutos',
        component: createProdutos,
        meta: {
            auth: true
        }
    },

    {
        path: '/produtos/index',
        name: 'indexProdutos',
        component: indexProdutos,
        meta: {
            auth: true
        }
    },
    {
        path: '/produtos/edit/:id',
        name: 'editProdutos',
        component: editProdutos,
        meta: {
            auth: true
        }
    },
    //pagamentos
    {
        path: '/pagamentos/create/:id',
        name: 'createPagamentos',
        component: createPagamentos,
        meta: {
            auth: true
        }
    },

    {
        path: '/pagamentos/index/:id',
        name: 'indexPagamentos',
        component: indexPagamentos,
        meta: {
            auth: true
        }
    },
    {
        path: '/pagamentos/edit/:id',
        name: 'editPagamentos',
        component: editPagamentos,
        meta: {
            auth: true
        }
    },
    


    //painel
    {
        path: '/painel',
        name: 'painel',
        component: DashBoardPage,
        meta: {
            auth: true
        }
    },


    {
        path: '/404',
        component: notFound
    },
    {
        path: '/',
        redirect: '/login'
    }

];
const router = createRouter({history: createWebHistory(), routes});
router.beforeEach((to) => {
    let middleware = new Middleware();
    if (!middleware.logout(to)) {
        middleware.routeExists(to);
        middleware.validateHash(to);

    }


})
router.afterEach((to) => {
    let middleware = new Middleware();
    middleware.setRegisterLastRouteBeforeLogin();
    middleware.userPermissions(to);
    middleware.finishLoading();
});
export default router;
import RequestHelper from "@/services/RequestHelper";

export default class PainelService {
    constructor(host) {
        this.host = host;
    }

    async contatos() {
        let request = new RequestHelper();
        let response = await request.postAuth(this.host + '/api/painel/contatos');
        return response.data.contatos;
    }


    async tarefas() {
        let request = new RequestHelper();
        let response = await request.postAuth(this.host + '/api/painel/tarefas');
        return response.data.tarefas;
    }


    async atribuidas() {
        let request = new RequestHelper();
        let response = await request.postAuth(this.host + '/api/painel/atribuidas');
        return response.data.atribuidas;
    }
}
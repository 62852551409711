<template>
  <div @click="goPage()">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "linkWidget",
  props: {
    href: String,

  },
  methods: {
    goPage() {
      this.$router.push(this.href)
    }
  }


}
</script>

<style scoped>

</style>
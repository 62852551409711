<template>
    <layout-page>
        <div class="card-header">
            <div class="row">
                <div class="col-12 ps-4 pt-3 ">
                    <div class="float-start">
                        <h5>Adicionar Estados</h5>
                    </div>
                    <div class="float-end">
                        <button-widget cor="azul" href="./index" tamanho="M">
                            Voltar
                        </button-widget>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <FormEstados></FormEstados>
                    <div class="col-4">
                    <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
                    </div>
                </div>
        </div>
    </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormEstados from "@/views/estados/FormEstados.vue";
import estadoService from "@/services/estado.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreateEstados",
    components: {FormEstados, LayoutPage, ButtonWidget},
    methods:{
        async sendForm(){
            let dataForm = {
        nome: document.getElementById('nome').value,
descritivo: document.getElementById('descritivo').value,


        }
            let estadosService = new estadoService();
            let response = await estadosService.store(dataForm);

            if(response.data?.id){
                location.href = '/estados/index';
            }else{
                if (response.response.data?.message){
                    toastr.error(response.response.data?.message);
                }else{
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped>
</style>

<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Usuários</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="../index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormUsers></FormUsers>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormUsers from "@/views/users/FormUsers.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import userService from "@/services/user.service";

export default {
  name: "EditUsers",
  components: { LayoutPage, ButtonWidget, FormUsers },
  methods: {
    async edit(id) {
      let usersService = new userService();
      let response = await usersService.view(id);
      document.getElementById('name').value = response.data.name;
      document.getElementById('email').value = response.data.email;
      document.getElementById('tipo').value = response.data.tipo;




    },
    async sendForm() {
      let dataForm = {
        name: document.getElementById('name').value,
        email: document.getElementById('email').value,
        tipo: document.getElementById('tipo').value,
        password: document.getElementById('password').value,


        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let usersService = new userService();
      let response = await usersService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped></style>

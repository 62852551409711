<template>
  <nav-bar></nav-bar>
  <menu-row></menu-row>
  <main class="content pt-5">

    <slot></slot>
  </main>

</template>
<script>


import NavBar from "@/components/page/navBar.vue";
import UserService from "@/services/user.service";
import MenuRow from "@/components/page/MenuRow.vue";

export default {
  name: "layoutPage",
  components: { MenuRow, NavBar },
  data() {
    return {
      countNotification: 0,
      notifications: 0,
      user: null,
      role: null,
      userName: ''
    }
  },
  methods: {
    async me() {
      let userService = await new UserService();
      this.user = await userService.me();
      this.userName = await this.user.data.name;
      this.role = await this.user.data.tipo;

      if (this.role === 'usuario') {
        let usuario_d_none = document.getElementsByClassName('usuario_d_none');
        for (let i = 0; i < usuario_d_none.length; i++) {
          usuario_d_none[i].classList.add('d-none');

        }
      }

    },
  },
  mounted() {
    this.me()

  }
}
</script>

<style scoped>
body {
  padding: 2px;
}

nav {
  height: 100px;
}
</style>

<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Tarefas</h5>
          </div>
          <div class="float-end">
            <button-widget id="voltar" cor="azul" href="../index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormTarefas></FormTarefas>
        <div class="col-12">
        <CreateAtributosTarefas></CreateAtributosTarefas>
        </div>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormTarefas from "@/views/tarefas/FormTarefas.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import tarefaService from "@/services/tarefa.service";
import CreateAtributosTarefas from "@/views/atributos_tarefas/CreateAtributosTarefas.vue";

export default {
  name: "EditTarefas",
  components: {CreateAtributosTarefas, LayoutPage, ButtonWidget, FormTarefas},
  methods: {
    async edit(id) {
      document.getElementById('voltar').classList.add('d-none');
      let tarefasService = new tarefaService();

      let response = await tarefasService.view(id);
      document.getElementById('descritivo').value = await response.data.descritivo;
      document.getElementById('data_inicio').value = await response.data.data_inicio;
      document.getElementById('data_fim').value = await response.data.data_fim;
      document.getElementById('alerta').value = await response.data.alerta;
      document.getElementById('contato_id').value = await response.data.contato_id;
      document.getElementById('produto_id').value = await response.data.produto_id;
      document.getElementById('atividade_id').value = await response.data.atividade_id;
      setTimeout(async ()=>{
        document.getElementById('prioridade_id').value = await response.data.prioridade_id;
        document.getElementById('categoria_id').value = await response.data.categoria_id;
        document.getElementById('estado_id').value = await response.data.estado_id;
        document.getElementById('tipo_id').value = await response.data.tipo_id;
        document.getElementById('sistema_id').value = await response.data.sistema_id;
        document.getElementById('voltar').classList.remove('d-none');
      },3000)


    },
    async sendForm() {
      let dataForm = {
        descritivo: document.getElementById('descritivo').value,
        data_inicio: document.getElementById('data_inicio').value,
        data_fim: document.getElementById('data_fim').value,
        alerta: document.getElementById('alerta').value,
        contato_id: document.getElementById('contato_id').value,
        produto_id: document.getElementById('produto_id').value,
        atividade_id: document.getElementById('atividade_id').value,
        prioridade_id: document.getElementById('prioridade_id').value,
        categoria_id: document.getElementById('categoria_id').value,
        estado_id: document.getElementById('estado_id').value,
        tipo_id: document.getElementById('tipo_id').value,
        sistema_id: document.getElementById('sistema_id').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let tarefasService = new tarefaService();
      let response = await tarefasService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  mounted() {

    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>

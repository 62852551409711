<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Arquivos</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" :href="'/arquivos/index/'+tarefa_id" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormArquivos></FormArquivos>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormArquivos from "@/views/arquivos/FormArquivos.vue";
import arquivoService from "@/services/arquivo.service";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateArquivos",
  components: {FormArquivos, LayoutPage, ButtonWidget},
  data() {
    return {
      tarefa_id: null
    }
  },
  methods: {
    async sendForm() {
     let path = document.getElementById('arquivo').value;
     let rowPath = path.split('\\');
      let file = rowPath[rowPath.length -1];
      console.log(file)

     let filename = file.split('.');
      let dataForm = {
        tarefa_id: this.tarefa_id,
        arquivo: document.getElementById('arquivo').dataset.value,
        nome: filename[0],
        ext:filename[1],

      }
      let arquivosService = new arquivoService();
      let response = await arquivosService.store(dataForm);

      if (response.data?.id) {
        location.href = '/arquivos/index/'+this.tarefa_id;
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },mounted() {
    this.tarefa_id = this.$route.params.id;
  }
}
</script>
<style scoped>
</style>

<template>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12" type="decimal" label="Valor " value="" name="valor"/>
  <input-form class-list="col-12" type="string" label="E-mail " value="" name="email"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormPagamentos",
  components: {InputForm},
  data() {
    return {}
  },
  methods: {},

  created() {
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>

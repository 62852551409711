import RequestHelper from "@/services/RequestHelper";


export default class PagamentosPixService {
    async create(transaction_amount, description,email) {

        let requestHelper = new RequestHelper();
        let  dataRequest = {
            transaction_amount: transaction_amount,
            description: description,
            email: email

        };

        return await requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pagamento/pix/pay', dataRequest);

    }

    async verify(id) {

        let requestHelper = new RequestHelper();
        let  dataRequest = {_method:'PUT'};

        return await requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pagamento/pix/verify/'+id, dataRequest);
    }

    confirm(pagamento_id) {

        let requestHelper = new RequestHelper();
        let  dataRequest = {_method:'PUT'};

        return requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/pagamento/pix/confirm/'+pagamento_id, dataRequest);
    }
}

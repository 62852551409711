<template>

  <div class="card m-2">
    <div class="body">
      <div class="row p-2">
        <FormAtributosTarefas></FormAtributosTarefas>
        <div class="col-4 p-2">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">+</button>
        </div>
        <div class="col-12">
          <div class="row">
            <IndexAtributosTarefas v-if="index"></IndexAtributosTarefas>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>
<script>


import FormAtributosTarefas from "@/views/atributos_tarefas/FormAtributosTarefas.vue";
import atributos_tarefaService from "@/services/atributos_tarefa.service";
import toastr from "toastr/build/toastr.min";
import IndexAtributosTarefas from "@/views/atributos_tarefas/IndexAtributosTarefas.vue";

export default {
  name: "CreateAtributosTarefas",
  data() {
    return {
      index:true
    }
  },
  components: {IndexAtributosTarefas, FormAtributosTarefas},
  methods: {
    async sendForm() {
      let dataForm = {
        tarefa_id: this.$route.params.id,
        user_id: document.getElementById('user_id').value,
      }
      let atributos_tarefasService = new atributos_tarefaService();
      let response = await atributos_tarefasService.store(dataForm);

      if (response.data?.id) {
        this.index = false;
        setTimeout(()=>{
          this.index = true;
        },500);
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>

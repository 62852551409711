<template>
  <nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
    <div class="d-flex align-items-center">
      <button class="navbar-toggler d-lg-none collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>

  <nav id="sidebarMenu" class="sidebar d-lg-block bg-gray-800 text-white collapse" data-simplebar>
    <div class="sidebar-inner px-4 pt-3">
      <div class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">

        <div class="collapse-close d-md-none">
          <a href="#sidebarMenu" data-bs-toggle="collapse"
             data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="true"
             aria-label="Toggle navigation">
            <svg class="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
            </svg>
          </a>
        </div>
      </div>
      <ul class="nav flex-column pt-3 pt-md-0">
        <li class="nav-item">
          <link-widget href="/painel" class="nav-link d-flex align-items-center">
          <span class="sidebar-icon">
            <img src="@/assets/logo-normal.png" height="60" alt="Volt Logo">
          </span>

          </link-widget>
        </li>
        <li @click="menu('dashboard')" :class="navItem==='dashboard'?'active':''" class="nav-item">
          <link-widget href="/painel" class="nav-link">
          <span class="sidebar-icon">
            <svg class="icon icon-xs me-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z"
      stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
          </span>
            <span class="sidebar-text">Dashboard</span>
          </link-widget>
        </li>
        <li @click="menu('tarefas')" :class="navItem==='tarefas'?'active':''" class="nav-item">
          <link-widget href="/tarefas/index" class="nav-link">
          <span class="sidebar-icon">
            <svg class="icon icon-xs me-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
      stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
          </span>
            <span class="sidebar-text">Caixa Entrada</span>
          </link-widget>
        </li>
        <li @click="menu('tarefas-me')" :class="navItem==='tarefas-by-me'?'active':''" class="nav-item">
          <link-widget href="/tarefas/me" class="nav-link">
          <span class="sidebar-icon">

            <svg class="icon icon-xs me-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
      stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
          </span>
            <span class="sidebar-text">Atribuida a mim</span>
          </link-widget>
        </li>
        <li @click="menu('tarefas-by-me')" :class="navItem==='tarefas-me'?'active':''" class="nav-item">
          <link-widget href="/tarefas/by-me" class="nav-link">
          <span class="sidebar-icon">

            <svg class="icon icon-xs me-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
      stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
          </span>
            <span class="sidebar-text">Criadas por mim</span>
          </link-widget>
        </li>

        <li @click="menu('tarefas-alerta')" :class="navItem==='tarefas-alerta'?'active':''" class="nav-item">
          <link-widget href="/tarefas/alerta" class="nav-link">
          <span class="sidebar-icon">
            <svg class="icon icon-xs me-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
      stroke-linecap="round" stroke-linejoin="round"></path>
</svg>

          </span>
            <span class="sidebar-text">Em Alerta</span>
          </link-widget>
        </li>
        <li @click="menu('tarefas-atraso')" :class="navItem==='tarefas-atraso'?'active':''" class="nav-item">
          <link-widget href="/tarefas/atraso" class="nav-link">
          <span class="sidebar-icon">
          <svg class="icon icon-xs me-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5"
               stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>
          </span>
            <span class="sidebar-text">Em Atraso</span>
          </link-widget>
        </li>
        <li @click="menu('contatos')" :class="navItem==='contatos'?'active':''" class="nav-item">
          <link-widget href="/contatos/index" class="nav-link">
          <span class="sidebar-icon">

            <svg class="icon icon-xs me-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5"
                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
      stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
          </span>
            <span class="sidebar-text">Contatos</span>
          </link-widget>
        </li>


      </ul>
    </div>
  </nav>

</template>

<script>


import LinkWidget from "@/components/widget/linkWidget.vue";

export default {
  name: "navBar",
  components: {LinkWidget},
  data() {
    return {
      navItem: null
    }

  },
  methods: {
    menu(item) {
      this.navItem = item;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
body {
  padding: 2px;
}

</style>
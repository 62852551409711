<template>
    <input-form class-list="col-12"  type="string" label="Nome " value="" name="nome"/>
<input-form class-list="col-12"  type="text" label="Descritivo " value="" name="descritivo"/>
<input-form class-list="col-12"  type="string" label="E-mail " value="" name="email"/>
<input-form class-list="col-12"  type="mask" mask="(00) 0 0000-0000" label="Telefone " value="" name="telefone"/>
<input-form class-list="col-12"  type="mask" mask="(00) 0 0000-0000" label="Celular " value="" name="celular"/>
<input-form class-list="col-12"  type="mask" mask="000.000.000-00" label="CPF " value="" name="cpf"/>
<input-form class-list="col-12"  type="mask" mask="00.000.000/0000-00" label="CNPJ " value="" name="cnpj"/>
<input-form class-list="col-12"  type="string" label="CEP " value="" name="cep"/>
<input-form class-list="col-12"  type="string" label="Logradouro " value="" name="logradouro"/>
<input-form class-list="col-12"  type="string" label="Número" value="" name="numero"/>
<input-form class-list="col-12"  type="string" label="Bairro " value="" name="bairro"/>
<input-form class-list="col-12"  type="string" label="Complemento " value="" name="complemento"/>
  <input-form placeholder="Selecione Cidade " class-list="col-12" type="select2" url="/api/cidades/list" label="Cidade " value="" name="cidade_id"/>
  <input-form placeholder="Selecione Tipo " class-list="col-12" type="select" :items="[{id:'BAIXO',message:'BAIXO',},{id:'MEDIO',message:'MEDIO',},{id:'ALTO',message:'ALTO',},]" label="Tipo " value="" name="tipo"/>
  <input-form placeholder="Selecione Categoria " class-list="col-12" type="select" :items="[{id:'LEAD',message:'LEAD',},{id:'CLIENTE',message:'CLIENTE',},{id:'PARCEIRO',message:'PARCEIRO',},]" label="Categoria " value="" name="categoria"/>
  <input-form placeholder="Selecione Empresas " class-list="col-12" type="select2" url="/api/empresas/list" label="Empresa " value="" name="empresa_id"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";



export default {
    name: "FormContatos",
    components: {InputForm},
    data() {
        return {}
    },
    methods:{},

created() {}
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>

import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";

export default class ContatosService {
    async list(search) {
        let dataRequest = {};
        let requestHelper = new RequestHelper();
        let helpers = new Helpers();

        if (!helpers.empty(search)) {
            dataRequest = {
                search: search
            };
        }

        return await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/contatos', dataRequest);

    }

    async delete(id) {
        let requestHelper = new RequestHelper();
        return await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/contatos/' + id);

    }

    async update(dataForm, id) {
        let request = new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/contatos/' + id, dataForm);
    }

    async store(dataForm) {
        if (!dataForm.parent_id) {
            delete dataForm.parent_id
        }
        let request = new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/contatos', dataForm);
    }

    async view(id) {
        let request = new RequestHelper();
        return await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/contatos/' + id, {});
    }

    async changeTipo(tipo, contato_id) {
        let request = new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/change/contatos/tipo/' + contato_id, {
            tipo: tipo,
            _method: "PUT"
        });

    }

    async changeCategoria(categoria, contato_id) {
        let request = new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/change/contatos/categoria/' + contato_id, {
            categoria: categoria,
            _method: "PUT"
        });

    }
}

import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";

export default class TarefasService {
    async list(search, payload = {}) {
        let dataRequest = payload;
        let requestHelper = new RequestHelper();
        let helpers = new Helpers();

        if (!helpers.empty(search)) {
            dataRequest = {
                search: search
            };
        }

        return await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas', dataRequest);

    }

    async me(search, payload = {}) {
        let dataRequest = payload;
        let requestHelper = new RequestHelper();
        let helpers = new Helpers();

        if (!helpers.empty(search)) {
            dataRequest = {
                search: search
            };
        }

        return await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas/me', dataRequest);

    }
    async byMe(search, payload = {}) {
        let dataRequest = payload;
        let requestHelper = new RequestHelper();
        let helpers = new Helpers();

        if (!helpers.empty(search)) {
            dataRequest = {
                search: search
            };
        }

        return await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas/byMe', dataRequest);

    }

    async alerta(search, payload = {}) {
        let dataRequest = payload;
        let requestHelper = new RequestHelper();
        let helpers = new Helpers();

        if (!helpers.empty(search)) {
            dataRequest = {
                search: search
            };
        }

        return await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas/alerta', dataRequest);

    }

    async atraso(search, payload = {}) {
        let dataRequest = payload;
        let requestHelper = new RequestHelper();
        let helpers = new Helpers();

        if (!helpers.empty(search)) {
            dataRequest = {
                search: search
            };
        }

        return await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas/atraso', dataRequest);

    }


    async delete(id) {
        let requestHelper = new RequestHelper();
        return await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas/' + id);

    }

    async update(dataForm, id) {
        let request = new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas/' + id, dataForm);
    }

    async store(dataForm) {
        if (!dataForm?.parent_id) {
            delete dataForm.parent_id
        }
        let request = new RequestHelper();
        return await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas', dataForm);
    }

    async view(id) {
        let request = new RequestHelper();
        return await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas/' + id, {});
    }

    static async  tarefasEstadoChart() {
        let request = new RequestHelper();
        return await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/tarefas/estado', {});
    }
}

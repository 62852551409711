<template>
  <layout-page>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 ps-4 pt-3 ">
            <div class="float-start">
              <h5> Arquivos</h5>
            </div>
            <div class="w-50">
              <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                     type="text" v-model="search">
            </div>
            <div class="float-end">
              <button-widget class="ms-4" cor="azul" href="/tarefas/index" tamanho="M">
                Voltar
              </button-widget>
            </div>
            <div class="float-end">
              <button-widget cor="azul" :href="'/arquivos/create/'+tarefa_id" tamanho="M">
                Adicionar
              </button-widget>
            </div>
          </div>

        </div>
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown">
                <button class="btn btn-system btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                  Ações
                </button>
                <ul class="dropdown-menu">

                  <li>
                    <span class="dropdown-item cursor-pointer" @click="deleteRow(row.id)">
                        <i class="bi bi-trash2-fill"></i>
                                             Apagar
                    </span>
                    <a :download="`${row.name}.${row.ext}`" class="dropdown-item cursor-pointer" :href="row.arquivo">
                      <i class="bi bi-trash2-fill"></i>
                      Download
                    </a>
                  </li>
                </ul>
              </div>


            </td>

            <td>


              <div v-if="String(row.arquivo).includes('image')" class="col-12">
                <img width="100" :src="row.arquivo" @click="modalOpen(row.arquivo)">
              </div>
              <div v-if="!String(row.arquivo).includes('image')" class="col-12">
                <i @click="openBase64File(row.arquivo)" style="font-size:100px " class="bi bi-file-earmark-richtext-fill"></i>

              </div>

              <strong></strong>{{ row.nome }}.{{ row.ext }}
            </td>

          </tr>


          </tbody>
        </table>

      </div>
    </div>
    <modal-widget-vue v-if="modal">

      <div class="row">
        <div class="col-12 text-center">
          <span style="font-size: 40px" @click="closeModal()" class="float-end pe-4">&times;</span>
          <img class="img-modal" :src="arquivo">
        </div>
      </div>

    </modal-widget-vue>

  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import arquivoService from "@/services/arquivo.service";
import ModalWidgetVue from "@/components/widget/modalWidgetVue.vue";

export default {
  name: "IndexArquivos",
  components: {ModalWidgetVue, ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null,
      tarefa_id: null,
      arquivo: null,
      modal: false,
      fileUrl:''
    }
  },
  methods: {
    async list() {
      this.tarefa_id = this.$route.params.id;

      let arquivosService = new arquivoService();
      let dataRow = await arquivosService.list(this.search, this.tarefa_id);
      let helpers = new Helpers();

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async deleteRow(id) {
      let arquivosService = new arquivoService();
      let dataRow = await arquivosService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    modalOpen(arquivo) {
      this.modal = true;
      this.arquivo = arquivo;
    }, closeModal() {
      this.modal = false;

    },
    openBase64File(base64) {
      // Detecta o tipo MIME do arquivo base64
      const mimeType = this.detectMimeType(base64);

      // Converte base64 para um array de bytes
      const byteCharacters = atob(base64.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Cria um blob a partir do array de bytes
      const blob = new Blob([byteArray], { type: mimeType });

      // Cria uma URL a partir do blob
      const fileUrl = URL.createObjectURL(blob);

      // Abre a URL em uma nova aba
      window.open(fileUrl, '_blank');
    },

    detectMimeType(base64) {
      // Extrai o cabeçalho de dados do base64 para determinar o tipo MIME
      const header = base64.split(',')[0];

      // Exemplos de verificação de tipos MIME comuns suportados pelo Chrome
      if (header.startsWith('data:image/jpeg')) {
        return 'image/jpeg';
      } else if (header.startsWith('data:image/png')) {
        return 'image/png';
      } else if (header.startsWith('data:application/pdf')) {
        return 'application/pdf';
      } else if (header.startsWith('data:text/plain')) {
        return 'text/plain';
      } else if (header.startsWith('data:application/json')) {
        return 'application/json';
      } else if (header.startsWith('data:application/xml')) {
        return 'application/xml';
      } else if (header.startsWith('data:application/vnd.ms-excel')) {
        return 'application/vnd.ms-excel';
      } else if (header.startsWith('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else if (header.startsWith('data:application/msword')) {
        return 'application/msword';
      } else if (header.startsWith('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else {
        // Caso não seja nenhum dos tipos acima, retorna um tipo genérico
        return 'application/octet-stream'; // Fallback para tipo genérico
      }
    }

  },
  created() {
    this.list();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
<style scoped>
.img-modal {
  height: 100%;
}
</style>

<template>
  <layout-page>
    <div class="row">


      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h5> Tarefas</h5>
          </div>
          <div class="col-4">
            <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa" type="text"
              v-model="search">
          </div>
          <div class="col-4">
            <select class="form-control search" name="departamento_id" id="departamento_id">
              <option value="">Selelcione o Departamento</option>
              <option v-for="departamento in departamentos" v-bind:key="departamento.id" :value="departamento.id">{{
                departamento.message }}</option>

            </select>
          </div>
          <div class="col-4">
            <select class="form-control search" name="estado_id" id="estado_id">
              <option value="">Selelcione o Estado</option>
              <option v-for="estado in estados" v-bind:key="estado.id" :value="estado.id">{{ estado.message }}</option>

            </select>
          </div>


          <div class="col-12 pt-3">

            <div class="float-end">

              <button-widget cor="azul" href="/tarefas/create" tamanho="M">
                Adicionar
              </button-widget>
            </div>
            <div class="float-end me-2">

              <button-widget class="usuario_d_none" cor="azul" href="/tarefas/import" tamanho="M">
                Importar
              </button-widget>
            </div>
          </div>


        </div>
        <table class="table table-hover cursor-pointer">
          <thead>
            <tr>
              <th>Ações</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in rows" :key="row.id">
              <td>
                <div class="dropdown">
                  <button class="btn btn-system btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Ações
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link class="dropdown-item" :to="'/tarefas/edit/' + row.id">
                        <i class="bi bi-pencil-square"></i>
                        Editar
                      </router-link>
                    </li>
                    <li>
                      <span class="dropdown-item cursor-pointer usuario_d_none" @click="deleteRow(row.id)">
                        <i class="bi bi-trash2-fill"></i>
                        Apagar

                      </span>
                    </li>
                    <li>
                      <router-link class="dropdown-item" :to="'/arquivos/index/' + row.id">
                        <i class="bi bi-file-fill"></i>
                        Anexos
                      </router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" :to="'/pagamentos/index/' + row.id">
                        <i class="bi bi-cash-coin"></i>
                        Pagamentos
                      </router-link>
                    </li>


                  </ul>
                </div>


              </td>

              <td @click="openTask(row.id)">
                <div class="col-12"><strong>Descrição: </strong>{{
                  new Helpers().empty(row.descritivo) ? 'Não há' : new StrUtil().truncateString(row.descritivo)
                  }}
                </div>
                <div class="col-12"><strong>Início : </strong>{{ row.data_inicio }}</div>
                <div class="col-12"><strong>Fim : </strong>{{ row.data_fim }}</div>
                <div class="col-12"><strong>Em alerta : </strong>{{ row.alerta }}</div>
                <div class="col-12"><strong>Contato : </strong>{{ row.contato }}</div>
                <div class="col-12"><strong>Tipo : </strong>{{ row.tipo }}</div>
                <div class="col-12"><strong>Sistema : </strong>{{ row.sistema }}</div>
                <div class="col-12"><strong>Criado por : </strong>{{ row.user }}</div>
              </td>
              <td @click="openTask(row.id)">
                <div class="col-12"><strong>Produto : </strong>{{ row.produto }}</div>
                <div class="col-12"><strong>Departamento : </strong>{{ row.departamento }}</div>
                <div class="col-12"><strong>Atividade : </strong>{{ row.atividade }}</div>
                <div class="col-12"><strong>Nivel de Prioridade : </strong>{{ row.prioridade }}</div>
                <div class="col-12"><strong>Categoria : </strong>{{ row.categoria }}</div>
                <div class="col-12"><strong>Estado : </strong>{{ row.estado }}</div>
                <div class="col-12"><strong>Atribuido : </strong>{{ row.atributo }}</div>

              </td>

            </tr>
            <tr v-if="rows == null">
              <td colspan="2">
                <div class="row">
                  <div class="col-12 d-flex justify-content-center">
                    <div class="loader"></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="rows === false">
              <td colspan="2" class="text-center"> Não há dados</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>


  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import toastr from "toastr/build/toastr.min";
import Helpers from "@/services/Helpers";
import tarefaService from "@/services/tarefa.service";
import EstadosService from "@/services/estado.service";
import StrUtil from "../../utils/str.util";
import DepartamentosService from "@/services/departamento.service";


export default {
  name: "IndexTarefas",
  computed: {
    Helpers() {
      return Helpers
    },
    StrUtil() {
      return StrUtil
    }
  },
  components: { ButtonWidget, LayoutPage },
  data() {
    return {
      rows: null,
      search: null,
      page: 1,
      estados: null,
      departamentos: null,

    }
  },
  methods: {
    async list() {

      let tarefasService = new tarefaService();
      let dataRow;
      let type = this.$route.params.type
      if (type === 'index') {
        dataRow = await tarefasService.list(this.search);
      } else if (type === 'me') {
        dataRow = await tarefasService.me(this.search);
      } else if (type === 'by-me') {
        dataRow = await tarefasService.byMe(this.search);
      } else if (type === 'alerta') {
        dataRow = await tarefasService.alerta(this.search);
      } else if (type === 'atraso') {
        dataRow = await tarefasService.atraso(this.search);
      }

      let helpers = new Helpers();

      if (dataRow?.data?.data !== undefined) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow?.response?.data)) {
        toastr.error('Houve um problema');
      } else {
        this.rows = false;
      }


    },
    async loadData() {


      let response = await new EstadosService().listRow();
      this.estados = response?.data

      response = await new DepartamentosService().listRowUser();
      this.departamentos = response?.data


    },
    async deleteRow(id) {
      let tarefasService = new tarefaService();
      let dataRow = await tarefasService.delete(id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    async filter() {
      let rows = document.querySelectorAll('select.search');
      for (const row of rows) {
        row.addEventListener('change', async () => {
          let filters = document.querySelectorAll('select.search');
          let payload = {};
          for (const filter of filters) {
            let filter_id = filter.value;
            let label = filter.id

            payload = { ...payload, [label]: filter_id };

          }


          let tarefasService = new tarefaService();
          let dataRow = await tarefasService.list(this.search, payload);
          let helpers = new Helpers();

          if (dataRow.data.data.length > 0) {
            this.rows = dataRow.data.data;

          } else if (!helpers.empty(dataRow.response?.data)) {
            toastr.error('Houve um problema');
          } else {
            this.rows = false;
          }
        });

      }
    },
    openTask(id) {
      this.$router.push('/tarefas/edit/' + id)
    },
    nextPage() {
      window.addEventListener('scroll', async () => {
        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        let maxScrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let url = location.pathname;

        if (maxScrollTop === scrollPosition && (url === '/tarefas/index' || url === '/tarefas/me' || url === '/tarefas/alerta' || url === '/tarefas/atraso')) {
          this.page++
          let payload = { page: this.page };
          let tarefasService = new tarefaService()
          let dataRow = await tarefasService.list(this.search, payload);

          this.rows = [...this.rows, ...dataRow.data.data]
        }
      });
    }


  },

  mounted() {
    this.list();
    this.loadData()
    this.filter()
    this.nextPage();

    let url = location.pathname;
    let links = ['/tarefas/index', '/tarefas/me', '/tarefas/alerta', '/tarefas/atraso'];
    let interval = setInterval(() => {
      let urlAtual = location.pathname;
      if (!links.includes(urlAtual)) {
        clearInterval(interval);
      }

      if (urlAtual !== url && urlAtual) {
        this.list();
        this.loadData()
        this.filter()
        url = location.pathname;
      }
    }, 200)


  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
<style>
.table td,
.table th {
  font-size: 12px !important;
}
</style>
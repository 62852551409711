<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Usuários</h5>
          </div>
          <div class="float-end">
            <button class="btn btn-system" type="button" @click="goBack()">Voltar</button>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormDepartamentoUsers></FormDepartamentoUsers>
        <div class="col-4">
          <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
        </div>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormDepartamentoUsers from "@/views/departamento_users/FormDepartamentoUsers.vue";

import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";
import departamento_userService from "@/services/departamento_user.service";

export default {
  name: "EditDepartamentoUsers",
  components: { LayoutPage, FormDepartamentoUsers },
  data() {
    return {
      departamento_id: null
    }
  },
  methods: {
    async edit(id) {
      let departamento_usersService = new departamento_userService();
      let response = await departamento_usersService.view(id);
      document.getElementById('user_id').value = response.data.user_id;


    },
    async sendForm() {
      let dataForm = {
        user_id: document.getElementById('user_id').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let id = this.$route.params.id;
      let departamento_usersService = new departamento_userService();
      let response = await departamento_usersService.update(dataForm, id);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    },
    goBack() {
      history.back()
    }
  },
  created() {
    this.edit(this.$route.params.id)
    this.departamento_id = this.$route.params.id;
  }
}
</script>

<style scoped></style>

<template>
    <div class="card-body">
        <div class="row">
            <FormContatoAnexos></FormContatoAnexos>
            <div class="col-4">
                <button class="btn btn-primary mt-4" type="button" @click="sendForm">Salvar</button>
            </div>
        </div>
    </div>
</template>
<script>

import FormContatoAnexos from "@/views/contato_anexos/FormContatoAnexos.vue";
import contato_anexoService from "@/services/contato_anexo.service";
import toastr from "toastr/build/toastr.min";

export default {
    name: "CreateContatoAnexos",
    components: { FormContatoAnexos },
    methods: {
        async sendForm() {
            let dataForm = {
                user_unidade_id: this.$route.params.id,
                arquivo: document.getElementById('arquivo').getAttribute('data-value'),
                nome: document.getElementById('arquivo').getAttribute('data-name'),
                ext: document.getElementById('arquivo').getAttribute('data-ext'),
            }
            let contato_anexosService = new contato_anexoService();
            let response = await contato_anexosService.store(dataForm);

            if (response.data?.id) {
                location.reload()
            } else {
                if (response.response.data?.message) {
                    toastr.error(response.response.data?.message);
                } else {
                    toastr.error('Houve um problema ao inserir');
                }

            }
        }
    }
}
</script>
<style scoped></style>

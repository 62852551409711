<template>
    <input-form class-list="col-12" type="string" label="Name  " value="" name="name" />
    <input-form class-list="col-12" type="string" label="E-mail " value="" name="email" />
    <input-form class-list="col-12" type="string" label="Password  " value="" name="password" />
    <input-form placeholder="Selecione Tipo " class-list="col-12" type="select" :items='[{ "id": "administrador", message: "Administrador" },
    { "id": "usuario", message: "Usuário" }]' label="Tipo " value="" name="tipo" />

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";



export default {
    name: "FormUsers",
    components: { InputForm },
    data() {
        return {}
    },
    methods: {},

    created() { }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>
